.MuiOutlinedInput-notchedOutline {
    border: 1px solid white !important;
    color: white !important;
}

.MuiOutlinedInput-input {
    color: white !important;
}

.MuiInputLabel-root {
    color: white !important;
}