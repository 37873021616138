progress {
  width: 100%;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: none;
  transform: translateY(25%);
}

progress[value]::-webkit-progress-bar {
  background-color: #D6E2EF;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
  height: 8px;
}

progress[value]::-webkit-progress-value {
  background-color: #225E9F;
  border-radius: 8px;
  transition: width ease 250ms;
}