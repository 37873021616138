@keyframes flash {
  0% {
    background-color: lightgrey;
  }

  50% {
    background-color: lightsteelblue;
  }

  100% {
    background-color: lightgrey;
  }
}

.ExamReportPage__LoaderBoxLg,
.ExamReportPage__LoaderBoxMd,
.ExamReportPage__LoaderBoxSm {
  animation-iteration-count: infinite;
  animation-duration: 2s;
  animation-name: flash;
  border-radius: 8px;
  filter: blur(5px);
}

.ExamReportPage__LoaderBoxLg {
  width: 80%;
  height: 30%;
}

.ExamReportPage__LoaderBoxMd {
  width: 45%;
  height: 100%
}

.ExamReportPage__LoaderBoxSm {
  width: 25%;
  height: 100%
}