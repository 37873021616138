.MuiOutlinedInput-input.MuiInputBase-input.MuiInputBase-inputSizeSmall {
    color: black !important;
}

.MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-sizeSmall {
    border: 1px solid #C4C4C4;
}

.css-1igy7s2 {
    float: left;
    padding: 2px 10px 24px 10px !important;
}

.MuiBox-root.css-19ho8hn {
    padding-top: 0;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiCard-root {
    padding: 0.5rem 1.75rem;
}

.MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-sizeSmall {
    width: calc(225px + 1vmin);
}