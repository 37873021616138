/* ----------------------------------------------------------------------------------------------------------------------- */
/*                                                         GENERAL                                                         */
/* ----------------------------------------------------------------------------------------------------------------------- */
*:focus {
  outline: none;
}

* { 
  -moz-box-sizing: border-box; 
  -webkit-box-sizing: border-box; 
   box-sizing: border-box; 
}

.App {
  width: 100%;
  max-width: 100%;
  margin-left: 0;
  margin-right: 0;
  line-height: 1.2;
}

.main {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1.5vmin);
  font-family: 'Pavanam', sans-serif;
  background-color: #ffffff;
}

.body {
  margin-top: 3em;
  font-family: 'Pavanam', sans-serif !important;
}

/* ----------------------------------------------------------------------------------------------------------------------- */
/*                                                          HEADER                                                         */
/* ----------------------------------------------------------------------------------------------------------------------- */

.header {
  z-index: 100;
  font-family: 'Pavanam', sans-serif;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  top: 0;
  height: 6em;
  margin: auto;
  -webkit-user-select: none;  
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: min(max(12px, 3vw), 22px);
}

.header-left {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  white-space: pre;
  height: 100%;
}

.header-right {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  white-space: pre;
  height: 100%;
}

.header .logo {
  height: 5em;
  width: auto;
  padding: 1em;
  margin: auto;
  pointer-events: none;
}

.header .profile {
  height: 3.25em;
  width: auto;
  border-radius: 100%;
  border: 1px solid #d3d3d3;
  pointer-events: none;
  
  margin: 0;
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.header-greeting-text {
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin: 0;
  align-self: center;
  padding-left: 2rem;
}

.header-greeting-logo {
  object-fit: cover;
}

.header-greeting-wrapper {
  display: flex;
}

.header-greeting-main {
  color: #000000;
  line-height: 2.25ch;
  font-weight: 400;
}

.header-greeting-secondary {
  line-height: 2.25ch;
  font-weight: 400;
}

.header-profile {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.header-profile-name {
  color: #000000;
  padding: 1em 1em 1em 0.25em;
  line-height: 2.25ch;
  font-weight: 400;
  margin: 0;
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

/* ----------------------------------------------------------------------------------------------------------------------- */
/*                                                     NAVIGATION MENU                                                     */
/* ----------------------------------------------------------------------------------------------------------------------- */

.header-menu-navigation {
  height: 2em !important;
  width: 2em !important;
  min-width: 2em !important;
  margin: 0 2em !important;
  border: 1px solid #3C879D !important;
  background-color: #69AABD !important;
  color: #ffffff !important;
  font-size: calc(10px + 1.5vmin);
  line-height: 2.25ch;
  font-weight: 400;
  margin: 0;
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

/* ----------------------------------------------------------------------------------------------------------------------- */
/*                                                      PATIENT SEARCH                                                     */
/* ----------------------------------------------------------------------------------------------------------------------- */
.body-grid {
  width: 50%;
}

/* .patient-search-textbox {
  
} */

.input-label {
  font-family: 'Pavanam', sans-serif !important;
}

/* ----------------------------------------------------------------------------------------------------------------------- */
/*                                                       PATIENT LIST                                                      */
/* ----------------------------------------------------------------------------------------------------------------------- */
th {
  color: #69AABD !important;
}

.dull {
  color: #C4C4C4 !important;
  text-align: -webkit-center;
}

/* ----------------------------------------------------------------------------------------------------------------------- */
/*                                                       PATIENT BAR                                                       */
/* ----------------------------------------------------------------------------------------------------------------------- */
.patient-bar {
  flex-direction: row;
}

.xray-card {
  height: fit-content;
  margin: 0;
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  background-color: rgba(229, 229, 229, 0.33) !important;
  align-items: center;
}

.xray-card-2 {
  height: fit-content;
  background-color: #FFFFFF !important;
  align-items: center;
  max-width: 250px;
  margin: 10px 12px 0 0;
  padding-left: 12px;
}

.xray-check {
  vertical-align: middle;
  margin-right: 1rem;
}

/* ----------------------------------------------------------------------------------------------------------------------- */
/*                                                     PATIENT OVERVIEW                                                    */
/* ----------------------------------------------------------------------------------------------------------------------- */
ul.no-bullets {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

/* ----------------------------------------------------------------------------------------------------------------------- */
/*                                                   X-RAY DETAILS MODAL                                                   */
/* ----------------------------------------------------------------------------------------------------------------------- */
.overlay {
  position: absolute; 
  bottom: 0; 
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.5); /* Black see-through */
  color: #f1f1f1; 
  width: 100%;
  transition: .5s ease;
  opacity:0;
  color: white;
  font-size: 20px;
  padding: 20px;
  text-align: center;
}

.image {
  display: block;
  width: 100%;
  height: auto;
}

/* ----------------------------------------------------------------------------------------------------------------------- */
/*                                                    TREATMENT PATHWAY                                                    */
/* ----------------------------------------------------------------------------------------------------------------------- */
.MuiListItemButton-root.Mui-selected {
  background-color: #3631B1 !important;
}

.Mui-selected .MuiTypography-root.MuiTypography-h6 {
  color: #f0f2f5;
}

/* ----------------------------------------------------------------------------------------------------------------------- */
/*                                                         PRO FORM                                                        */
/* ----------------------------------------------------------------------------------------------------------------------- */


/* ----------------------------------------------------------------------------------------------------------------------- */
/*                                                          FOOTER                                                         */
/* ----------------------------------------------------------------------------------------------------------------------- */
.footer {
  background: rgba(105, 170, 189, 0.08);
  position: absolute;
  bottom: 0;
  height: 16vh;
  width: 100%;
}

.footer-wrapper {
  width: 75%;
  height: 100%;
  position: relative;
  margin: auto;
}

.get-started-button {
  margin: 0 2em !important;
  border: 1px solid #3C879D !important;
  background-color: #69AABD !important;
  color: #ffffff !important;
  right: 0;
  text-transform: none !important;
  padding: 0.5em 5em !important;
  margin-right: 2em !important;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.analyze-button {
  margin: 0 2em !important;
  border: 1px solid #3C879D !important;
  background-color: #69AABD !important;
  color: #ffffff !important;
  right: 0;
  text-transform: none !important;
  padding: 0.5em 5em !important;
  margin-right: 2em !important;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  float: right;
}

.return-button {
  margin: 0 2em !important;
  border: 1px solid #3C879D !important;
  background-color: #69AABD !important;
  color: #ffffff !important;
  left: 0;
  text-transform: none !important;
  padding: 0.5em 5em !important;
  margin-right: 2em !important;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

