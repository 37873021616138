.PDF__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 42rem;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.PDF__container.fade-out-btm::after {
  content: '';
  width: 100%;
  height: 2rem;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  background: linear-gradient(0deg, white 20%, rgba(255,0,0,0) 100%);
}

.PDF__container__load {
  margin-top: 1em;
  color: white;
}

.PDF__container__document {
  width: 100%;
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
  border-radius: 8px;
  scrollbar-width: thin;
  scrollbar-color: lightgrey transparent;
}

.PDF__container__document::-webkit-scrollbar {
  width: 8px;
}

.PDF__container__document::-webkit-scrollbar-track {
  background: transparent;
}

.PDF__container__document::-webkit-scrollbar-thumb {
  background-color: lightgrey;
  border-radius: 10px;
}

.PDF__container__document .react-pdf__Document {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.PDF__container__document .react-pdf__Page {
  max-width: calc(100% - 2em);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  margin: .5rem 1rem 1rem 1rem;
}

.PDF__container__document .react-pdf__Page canvas {
  max-width: 100%;
  height: auto !important;
}
