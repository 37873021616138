.OrganizationPage__AlertContainer {
  width: 100%;
  transition: margin-top 250ms ease;
}

.OrganizationPage__AlertContainer.open {
  /* opacity: 1; */
  margin-top: 0;
}

.OrganizationPage__AlertContainer.closed {
  /* opacity: 0; */
  margin-top: -100%;
}